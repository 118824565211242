БеуьздфеуЮ
<template>
  <layout width="420">
    <template slot="header">
      Alarms
    </template>
    <template>
      <v-form>
        <v-combobox v-model="state.tags" label="Optional tags" multiple chips />
        <object-item-alarms
          v-model="state"
          :object-item-type="objectItemTypes.geoItem"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { ref, onBeforeMount } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { schemasService } from '@/modules/common/api';
import { prepareObject } from '@/provider/utils';
import ObjectItemAlarms from '@/modules/objects/ui/object-card/general/ObjectItemAlarms.vue';
import Layout from '@/components/popup/PopupLayoutDefault';
import { geoItemService } from '@/modules/object-types/api';
import {
  defaultGeoAlarm,
  objectItemTypes
} from '@/modules/objects/ui/object-card/general/item-helpers';

export default {
  name: 'GeoItemAlarmsDefaultEdit',
  components: {
    ObjectItemAlarms,
    Layout
  },
  props: {
    geoItemId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      tags: [],
      alarms: []
    });
    const geoItem = ref({});

    const handleSubmit = async () => {
      await schemasService.updateValues(
        [
          {
            id: geoItem.value.stateAlarms.id,
            value: state.value.alarms
          },
          {
            id: geoItem.value.stateTags.id,
            value: state.value.tags
          }
        ],
        props.geoItemId
      );
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onBeforeMount(async () => {
      geoItem.value = prepareObject(
        await geoItemService.fetch(props.geoItemId)
      );
      const alarmCount = geoItem.value.commonAlertCount.value;
      for (let i = 0; i < alarmCount; i++) {
        state.value.alarms.push({
          ...defaultGeoAlarm(),
          ...(geoItem.value.stateAlarms?.value?.[i] || {})
        });
      }
      state.value.tags = geoItem.value.stateTags.value || [];
    });

    return {
      state,
      loading,
      submit,
      objectItemTypes
    };
  }
};
</script>
