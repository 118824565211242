import { render, staticRenderFns } from "./GeoItemAlarmsDefaultEdit.vue?vue&type=template&id=e94830f6&"
import script from "./GeoItemAlarmsDefaultEdit.vue?vue&type=script&lang=js&"
export * from "./GeoItemAlarmsDefaultEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCombobox,VForm,VSpacer})
